<template>
	<div class="order__update">
		<div class="order__item" @click="handleShowCollapse(order)">
			<span class="order__id">{{ order.id }}</span>
			<div class="order__tag__missing">
				<div v-if="displayTag" class="mr-3 actual-price">
					<span>{{ $t("OrdersTitle.MissingActual") }}</span>
				</div>
				<span v-if="!isShow" class="order__dropdown">
					<font-awesome-icon :icon="['fas', 'caret-down']" />
				</span>
				<span v-else class="order__dropdown">
					<font-awesome-icon :icon="['fas', 'caret-up']" />
				</span>
			</div>
		</div>
		<CCollapse :show="isShow">
			<OrderFormWeb
				v-if="isShow"
				:id="order.id"
				:is-view="isView"
				:order-detail="orderDetail"
				@cancel-order="cancelOrder"
				@update="updateOrder"
			/>
		</CCollapse>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { USER_ROLE } from "../../../shared/plugins/constants"
import { GET_ORDER } from "../store/action-types"
const { mapActions } = createNamespacedHelpers("orders")
const { mapState } = createNamespacedHelpers("authentication")
export default {
	name: "OrderWebItem",
	components: {
		OrderFormWeb: () => import("./OrderFormWeb.vue"),
	},
	props: {
		order: {
			type: Object,
			default: () => {},
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		originalId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			isShow: false,
			orderDetail: null,
		}
	},
	computed: {
		...mapState(["currentUser"]),
		isSuperAdmin() {
			if (this.currentUser.role === USER_ROLE.SUPER_ADMIN) {
				return true
			}
			return false
		},
		displayTag() {
			if (this.isSuperAdmin && this.order.price === null) {
				return true
			}
			return false
		},
	},
	methods: {
		...mapActions({ GET_ORDER }),
		async handleShowCollapse(order) {
			if (!this.isShow) {
				const params = {
					data: {
						orderId: order.id,
						originalId: this.originalId,
					},
					notLoading: false,
				}
				const res = await this.GET_ORDER(params)
				this.orderDetail = res
			}
			this.isShow = !this.isShow
		},
		async cancelOrder() {
			this.isShow = !this.isShow
		},
		updateOrder() {
			this.$router.push({
				name: "UpdateOrderWeb",
				params: { id: this.order.id },
				query: { original_ID: this.originalId },
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.order {
	height: 100%;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	&__update {
		min-height: 32px;
		padding: 5px 13px;
		border-radius: 0.25rem;
		border: 1px solid $base-color;
		margin-bottom: 15px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}

	&__dropdown {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__tag__missing {
		display: flex;
		justify-content: space-between;
		align-content: center;
	}
}

.actual-price {
	padding: 4px 8px;
	border: 1px solid $base-color;
	border-radius: 0.25rem;
	cursor: auto;
	color: $base-color;
}
</style>
